html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#rootContainer {
  background: #06BA54;
  height: 100%;
}

#root {
  background: #06BA54 no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 2%;
}

#mePinMarker {
  width: 60px;
  height: 78px;
  background-image: url('../assets/images/pinmefinal.png');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*background-color: rgba(0, 0, 0, 0.2);*/
  cursor: pointer;
}

#certifiedPopup {
  display: flex;
  flex-flow: row nowrap;
  width: 300px;
}

#certifiedPopup img{
  width: 100px;
  margin-right: 8px;
}

#certifiedPopupContent > span{
  color: #06ba54;
  text-transform: uppercase;
  font-size: 12px;
}

#certifiedPopupContent h6{
  margin: 4px 0;
  font-size: 16px;
}

#certifiedPopupContentAddress span:nth-child(1){
  color: black;
  text-transform: capitalize;
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
}

#certifiedPopupContentAddress span:nth-child(2){
  color: black;
  text-transform: capitalize;
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  margin-top: 4px;
}

#certifiedPopupContentAddress span:nth-child(3){
  color: black;
  text-transform: capitalize;
  display: block;
  font-weight: bold;
  font-size: 12px;
  margin-top: 6px;
}

#fixedProvider {
  width: 280px;
}

#fixedProvider img{
  width: 24px;
  height: 20px;
  object-fit: contain;
  margin-right: 8px;
  float: left;
}

#fixedProvider h6{
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

#fixedProviderContent {
  display: block;
  margin-top: 6px;
  border-top: 1px solid rgba(0, 0, 0, 0.6);
}

#fixedProviderContent h5{
  margin: 6px 0;
}

#fixedProviderContent span{
  font-size: 14px;
}

/*#map > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(4) {
  width: 320px !important;
}

#map > div > div > div:nth-child(1) > div:nth-child(4) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(2) {
  width: 210px !important;
}

.gm-style-iw {
  max-width: 260px !important;
  width: 260px;
}

.gm-style-iw-c {
  max-width: 260px !important;
  width: 260px;
}

.gm-style-iw-d {
  max-width: 256px !important;
  width: 256px;
}

.fixedProvider-infowindow {
  overflow-y: hidden;
  display: flex;
  flex-flow: column wrap;
  width: 236px;
}

.fixedProvider-titleBar {
  max-height: 32px;
  border-bottom: 0.5px solid #828282;
}

.fixedProvider-titleBar img {
  height: 24px;
  width: 24px;
  float: left;
  margin-right: 8px;
}

.fixedProvider-titleBar h6 {
  font-weight: bold;
  font-size: 16px;
  margin: 2px 0 0 0;
}

.fixedProvider-content h6 {
  font-weight: bold;
  font-size: 12px;
  margin: 6px 0 2px 0;
}

.fixedProvider-content div span {
  font-size: 11px;
  font-weight: bold;
}

.infowindow {
  overflow-y: hidden;
  display: flex;
  flex-flow: row wrap;
  width: 236px;
}

.infowindow #venue-image {
  max-width: 80px;
  margin-right: 6px;
  margin-top: 4px;
  height: 94px;
}

.infowindow .certified_badge {
  position: absolute;
  top: 30px;
  right: 4px;
  width: 24px;
}

.infowindow .certified {
  color: #06BA54;;
  text-transform: uppercase;
  font-weight: bold;
}

.infowindow h6 {
  font-weight: bold;
  font-size: 16px;
  margin: 2px 0;
}

.infowindow .point-rating {
  color: #9B9B9B;
  padding: 4px 0;
}

.infowindow .point-address {
  width: 150px;
}

.infowindow .point-address img {
  display: inline-block;
  width: 20px;
}

.infowindow .point-address span:nth-child(1) {
  font-weight: normal;
  margin: 6px 0;
}

.infowindow .point-address span:nth-child(2) {
  display: block;
  font-weight: normal;
}

.infowindow .point-address span:nth-child(3) {
  font-weight: bold;
  font-size: 12px;
}

.infowindow .point-address span:nth-child(5) {
  font-weight: bold;
  font-size: 10px;
  margin-top: -2px;
}

.infowindow .point-address button {
  width: 100%;
  background-color: #06BA54;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px;
}

.widget-container .widget-svg {
  width: 32px
}*/

/* Mapbox */

.mapboxgl-ctrl-geocoder {
  padding: 8px 0;
}

.mapboxgl-ctrl-geocoder--input {
  padding: 6px 18px;
}

.mapboxgl-ctrl-geocoder--icon {
  top: 16px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 20px;
  width: 32px
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  top: 14px !important;
}

.mapboxgl-ctrl-geocoder .suggestions {
  z-index: 3000 !important;
}


.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-top-right {
  width: 100%;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  float: none !important;
  margin: 12px auto !important;
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    padding: 8px 0;
    width: 100% !important;
    max-width: 100% !important;
    float: none !important;
    margin: 12px auto !important;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 16px !important;
  }
}

